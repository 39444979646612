	.bubble-logo .diellogo {
		fill: #fff;
	}
.bubble-txt {
	padding: 120px 0;

	.btn-transparent {
		background-color: rgba(255,255,255,0.2);
		border-color: rgba(255,255,255,0.2);
		color: #fff;
	}	
	.bubble {
		margin-bottom: 25px;		
		background: url(../images/bubble.svg) no-repeat center center;
		background-size: contain;
		text-align: center;
		h3 {
			padding: 50px;
			padding-bottom: 90px;
		}
	.txt {
		padding-bottom: 100px;
	}
	}
}

//Smartphone Landscape 576+
@include media-breakpoint-up(sm) {
}

//Tablet 768+
@include media-breakpoint-up(md) {
	.content {
		margin-top: -90px;
		padding-top: 90px;
		background-size: 300px !important;
	}
	.bubble-txt {
		.bubble {
		margin-bottom: 0;
			h3 {
				font-size: 2em;
				padding: 80px;
				padding-bottom: 140px;
			}			
		}
	}
}

// Desktop 992+
@include media-breakpoint-up(lg) {
}

// Desktop 1200+
@include media-breakpoint-up(xl) {
	.logo .container {
		background-size: inherit !important;		
	}	
}

@include media-breakpoint-between(xs,md) {
.logo .container {
	background: none !important;
}	
}