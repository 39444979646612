.kontakt {
	background-size: contain !important;
	.content {
		margin-top: 0;
		padding-top: 0;
	}
	.row {
		background-color: rgba(238,238,238,0.85);
	}
}
//Smartphone Landscape 576+
@include media-breakpoint-up(sm) {
	}

//Tablet 768+
@include media-breakpoint-up(md) {
	.kontakt {
		.row {
		background-color: rgba(238,238,238,0);
		}	
	}
}

// Desktop 992+
@include media-breakpoint-up(lg) {
	
}

// Desktop 1200+
@include media-breakpoint-up(xl) {
	
}