
.sf-menu {
	font-family: 'Montserrat';
	font-weight: 500;
	font-size: 0.9em;

	letter-spacing: 0.02em;
	text-transform: uppercase;
	margin: 0 auto;
	width: 100%;
	background: none;
    text-align: center;
		li {			
			background: #fff;
		    text-align: center;
		    display: block;
		    float: none;
			width: 100%;
			:hover {
			}
			:active {
			background: theme-color-level(soft, -10);
			}			
			a {
				text-align: center;
				border: none;
				color: $primary;
				background-color: #fff;
			}
		}
}


//Smartphone Landscape 576+
@include media-breakpoint-up(sm) {
	.sf-menu {
		li {
			width: auto;
			display: inline-block;
		}
	}
}

//Tablet 768+
@include media-breakpoint-up(md) {
}

// Desktop 992+
@include media-breakpoint-up(lg) {
}

// Desktop 1200+
@include media-breakpoint-up(xl) {
	
}