// Global Stlyes
.section-24 {
	a {
		color: #fff;
		text-decoration: underline;
	}
}

.fp-scroller {
	min-height: 100%;	
}

.logo {
	width: 100%;
	height: 100%;
	position: absolute !important;
	top: 0px;
	padding: 0 !important;
	margin: 0 !important;
	.container, .container .row {
		width: 100%;
		height: 100%;
	}
	svg {
		margin-left: 40px;
		margin-top: 40px;
	}
}

.txt {
	margin-bottom: 50px;		
}

.diel {
	display: block;
	text-transform: uppercase;
	font-size: 0.5em;
}
.byline {
	font-size: 0.85em;
}





/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/montserrat-v12-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
       url('../fonts/montserrat-v12-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat-v12-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat-v12-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat-v12-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat-v12-latin-500.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-800 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/montserrat-v12-latin-800.eot'); /* IE9 Compat Modes */
  src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
       url('../fonts/montserrat-v12-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat-v12-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat-v12-latin-800.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat-v12-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat-v12-latin-800.svg#Montserrat') format('svg'); /* Legacy iOS */
}



.no-js {
	.section {
		min-height: 300px;
	}
}

h1,h2,h3,h4 {
	text-transform: uppercase;
}

body, p, a, ul, li, h1, h2 {
	font-family: 'Montserrat';
	font-weight: 500;
}

h3, h4, strong {
	font-family: 'Montserrat';
	font-weight: 800;
}

//Smartphone Landscape 576+
@include media-breakpoint-up(sm) {
	
}

//Tablet 768+
@include media-breakpoint-up(md) {
	
}

// Desktop 992+
@include media-breakpoint-up(lg) {
	
}

// Desktop 1200+
@include media-breakpoint-up(xl) {
	
}