.txt-icons {
}

.icon {
	text-align: center;
	i {
		color: $business;
		font-size: 3em;
	}
	p {
		font-size: 0.8em;
	}
}


//Smartphone Landscape 576+
@include media-breakpoint-up(sm) {
}

//Tablet 768+
@include media-breakpoint-up(md) {
	
}

// Desktop 992+
@include media-breakpoint-up(lg) {
	
}

// Desktop 1200+
@include media-breakpoint-up(xl) {
	
}
