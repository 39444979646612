.header {
	padding: 0;
	margin: 0;
	background: #fff;	
	.container .row {
	min-height: 60px;
	}
	.toggler {
		a, a:visited {
		color: $dark;
		}
	}
}

.logo {
	position: relative;
	text-align: center;
	padding-top: 20px;
	padding-bottom: 20px;
	svg {
	width: 150px;
	}
}

//Smartphone Landscape 576+
@include media-breakpoint-up(sm) {
	
}

//Tablet 768+
@include media-breakpoint-up(md) {
	.logo {
		text-align: left;
		padding-left: 50px;		
	}
}

// Desktop 992+
@include media-breakpoint-up(lg) {
	
}

// Desktop 1200+
@include media-breakpoint-up(xl) {
}