
//Smartphone Landscape 576+
@include media-breakpoint-up(sm) {
	
}

//Tablet 768+
@include media-breakpoint-up(md) {
	
}

// Desktop 992+
@include media-breakpoint-up(lg) {
.arrowDown {
    position:fixed;
	box-sizing: border-box;    
    z-index:99;
    cursor: pointer;
    bottom: 30px;	
	left: 50%;
	width: 24px;
	height: 24px;
	margin-left: -12px;
	border-left: 1px solid #fff;
	border-bottom: 1px solid #fff;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
}
  	
}

// Desktop 1200+
@include media-breakpoint-up(xl) {
	
}