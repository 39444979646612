.alertBox {
  hyphens: auto;
  padding: 10px;
  h1 {
    padding-bottom: 10px;
  }
  p {
    margin-bottom: 5px;
  }
  .container {
    border-width: 3px !important;
  }
}